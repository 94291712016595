import React from "react";
import Experts from "../components/Experts/Experts";
// import Loader from './../components/Loader/Loader';
import bg from "../assets/images/about_bg.png";
// import { useGetAboutUsPageDataQuery } from "../Services/dataService";
import Slider from "react-slick";

export const About = () => {
  // const {isLoading } = useGetAboutUsPageDataQuery()
  // if (isLoading) {
  //   return <Loader isLoading={isLoading} />

  // }
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    pauseOnHover: false,
    cssEase: "linear",
  };
  return (
    <>
      <div className="d-flex justify-content-center py-2">
        <div className="carousel-container pt-2 w-100">
          <Slider {...settings}>
            <div className="slick-slider d-flex justify-content-start">
              <img
                src={bg}
                alt="service"
                className="w-100 card-img"
              />
              <div className="card-img-overlay row g-lg-3 g-md-3 h-100 px-lg-2 pt-lg-3 pt-md-2">
                <div className="col-xxl-4 d-md-block col-lg-6 col-md-6 px-lg-5">
                  <div className="d-flex h-100 flex-column justify-content-center">
                    <div className="background-blur">
                    <div className="p-2">
                      <h1 className="ff-playfair text-white">About</h1>
                    </div>
                    <div className="p-2">
                      <p className="fs-5 text-justify text-white justify mb-lg-3 ff-montserrat">
                        We are a passionate team of thinkers and doers, contributing with our abilities to have our customers design better,
                        build better and sell better their products, services, brands, ventures, and systems.</p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <Experts />
    </>
  );
};
