import indSlider1 from "./../assets/images/industry_1.jpg";
import indSlider2 from "./../assets/images/industry_2.jpg";
import indSlider3 from "./../assets/images/industry_3.jpg";
import indSlider4 from "./../assets/images/industry_4.jpg";
import indSlider5 from "./../assets/images/industry_5.jpg";
import indSlider6 from "./../assets/images/industry_6.jpg";


export const Indutries = [
  {
    id: 0,
    imgUrl: indSlider1,
    imageDark: false,
    title: "Agriculture",
    about:
      "We work with bold entrepreneurs and organizations in the Agriculture industries and sectors, including yours."
  },
  {
    id: 1,
    imageDark: true,
    imgUrl: indSlider2,
    title: "Education",
    about:
      "We work with bold entrepreneurs and organizations in Education institutions, including yours."
  },
  {
    id: 2,
    imageDark: true,
    imgUrl: indSlider3,
    title: "Manufacturing",
    about:
      "We work with bold entrepreneurs and organizations in the Manufacturing industries and sectors, including yours."
  },
  {
    id: 3,
    imgUrl: indSlider4,
    imageDark: false,
    title: "E-Commerce",
    about:
      "We work with bold entrepreneurs and organizations on the E-Commerce platform, including yours."
  },
  {
    id: 4,
    imgUrl: indSlider5,
    imageDark: false,
    title: "Social",
    about:
      "We work with bold entrepreneurs and organizations on the Social sectors, including yours."
  },
  {
    id: 5,
    imgUrl: indSlider6,
    imageDark: true,
    title: "CyberSecurity",
    about:
      "We work with bold entrepreneurs and organizations in CyberSecurity industries and sectors, including yours."
  },
];
