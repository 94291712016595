import React from "react";
import "../components/Carousel/Carousel.css";
import "../components/sectionHeader/sectionHeader.css";
import bg from "./../assets/images/contact_us_bg.png"
import ProjectForm from "./../components/ContactForm/ProjectForm";
// import { useGetContactUsDataQuery } from "../Services/dataService";
// import Loader from "./../components/Loader/Loader";
import Slider from "react-slick";
const ContactUs = () => {

  // const { data, isLoading } = useGetServicePageDataQuery();
  // React.useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // }, []);
  // if (isLoading) {
  //   return <Loader isLoading={isLoading} />;
  // }
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    cssEase: "linear",
  };
  return (
    <div className="container-fluid px-lg-5 px-md-4">
      <div className="d-flex justify-content-center py-2">
        <div className="carousel-container pt-2 w-100" >
          <Slider {...settings}>
            <div className="slick-slider d-flex justify-content-start">
              <img
                src={bg}
                alt="service"
                className="w-100 img-fluid"
              />
              <div className="row card-img-overlay g-lg-3 g-md-3 h-100 px-lg-2 pt-lg-3 pt-md-2">
                <div className="col-xxl-5 d-md-block col-lg-6 col-md-6 px-lg-5">
                  <div className="d-flex h-100 flex-column justify-content-center">
                    <div className="p-2">
                      <h1 className="ff-playfair text-white">
                        Connect
                      </h1>
                    </div>
                    <div className="p-2">
                      <p className="fs-5 text-justify text-white justify mb-lg-3 ff-montserrat">
                        with your trusted Innovation partners,
                        talk to Baelworks </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div className="container-fluid pt-1 pt-md-3">
        <p
          style={{ whiteSpace: "pre-wrap" }}
          className="fs-2 py-1 py-md-3 ff-montserrat fw-normal text-justify text-black"
        >
          We are with you in the long-haul crafting, building, launching, and making better, your products, services, brands, ventures, and systems.<br/><br/>
          We bring to life our conversations with capabilities and perspective at the cusp of technology, business, design to address every challenge you are trying to solve. To know more..
        </p>
        
      </div>
      <div className="pb-10">
        <ProjectForm />
      </div>
    </div>
  );
};

export default ContactUs;
