import React from "react";
import "./Project.css";
import { aboutHome } from "./../../Data/HomeData"

const Projects = () => {
  return (
    <section className="my-5 py-xxl-5">
      {aboutHome.map((about, index) => {
        return (
          <div className="overflow-hidden container-fluid px-lg-5 px-md-4" key={index}>
            <div className="section-heading">
              <h1 className="ff-playfair fw-bold mb-xxl-4 text-justify text-black">
                {about.heading}
              </h1>
            </div>
            <div className="row gy-3 ">
              <div className="col-12 section-para">
                <pre style={{ whiteSpace: "pre-wrap" }} className="ff-montserrat text-black fw-normal text-justify">
                  {about.description}<br />{about.content}
                </pre>
              </div>
            </div>
          </div>
        )
      })
      }
    </section>
  );
};

export default Projects;
