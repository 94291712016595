import CEO from "./../assets/images/CEO.png";
import COO from "./../assets/images/COO.png";
import POST from "./../assets/images/POST.png";
import CPO from "./../assets/images/CPO.png";
// import MP from "./../assets/images/MP.png";
// import MDD from "./../assets/images/MDD.png";
export const ManagementTeam = [
  {
    id: 0,
    name: "Aditya Trivedi",
    positionLabel: "",
    role: "Technology Consultant",
    imageUrl: CEO,
    mailId: "",
    about:
      `Aditya works at the cusp of design, business and technology and has a background in product management with a design centric point of view. Aditya is an IIM-Bangalore grad with a career spanning 22 years in the technology industry in Bangalore. He divides his time between Bangalore, Ujjain and Indore. 
He is working at building Bharat-driven innovations for industry and agritech as an entrepreneur through his products and innovations agency. Leveraging talent in the rural diversity of Madhya Pradesh. He is also engaged in advisory and consulting for start-ups, incubators, enterprises and investors for different domains. He is helping rural youth in Madhya Pradesh utilizing their strengths through his venture.
He is also an adjunct professor of practice, school of design, Avantika university, Ujjain (MIT-ID, Pune campus). While providing solutions for farmers and Agri-ecosystem through his agritech venture, he is exploring the domains of design management, user experience, service design and design thinking. 
Aditya has also worked in social sector for more than a decade and a half and is on the board of Sant Singaji institute of science and management in rural Madhya Pradesh (a Harvard business review case study has been written on this college). He is rediscovering a meeting ground in his interests such as design, drawing, photography, temple architecture, Hindi and regional literature from across India and exploring places of dharmic and cultural importance in Ujjain and Madhya Pradesh.`,
    profile: "https://www.linkedin.com/in/adityatrivedi",
  },
  {
    id: 1,
    name: "Pranjal Dubey",
    positionLabel: "",
    role: "CEO & COO",
    imageUrl: COO,
    mailID: "",
    about:
      `Pranjal Dubey is a Social Innovator, problem solver at heart and has a passion for grooming talent. Impact of Pranjal’ s work is visible from the economic upliftment of students and their families in rural areas which did not have access to quality education.
In over a decade Pranjal has trained talent who are placed in globally renowned companies and organizations.Pranjal was a Program Director at SAP for 11 years before he had an epiphany that led him to set the foundation of “Singaji Educational Society” in 2010 a Rural Youth Educational Institute in central Madhya Pradesh.
The institute has been pioneer is setting benchmarks in rural youth education in central India. (a Harvard Business Review case study has been written on this college); Pranjal has contributed in setting up software and technology companies that creates opportunities for talent graduating from the college.`,
    profile: "https://www.linkedin.com/in/pranjaldubey",
  },
  {
    id: 2,
    name: "Shalin Trivedi",
    positionLabel: "",
    role: "CMO",
    imageUrl: POST,
    mailId: "",
    about:
      `Shalin is a versatile professional with over 17 years of experience with leading brands in Telecom, Retail, Food & Beverage and Technology. His expertise includes Product Marketing, Data Analytics, Brand Management, Digital Marketing, Sales and Business Development for B2B and B2C brands.
Shalin is an MBA from Indian Institute of Technology Delhi and has done an Integrated Program in Business Analytics from IIM Indore. Shalin has led operations and strategy for Technology, Retail, Manufacturing and Food & Beverages that gives him a unique perspective of opportunities, best practices and challenges across industries.
Shalin loves to read and is an avid marathoner having competed across several such marathons.`,
    profile: "https://www.linkedin.com/in/shalin-trivedi",
  },
  {
    id: 3,
    name: "Manajit Rath",
    // positionLabel: "Technology",
    role: "CPO, Reshamandi",
    imageUrl: CPO,
    mailId: "manajit@baelworks.com",
    about:
      `Manajit has over two decades of experience conceptualising and building several products, that were first to the market. In his current role as the Chief Product Officer at Reshamandi, he has built a strong, high performance, customer-centric product team and brought structure, improved agility and depth of problem solving into the organisation. His background as a technologist gives him an edge to concieve highly scalable and secure products. Extremely passionate about solving complex problems using technology and data, in his previous stint as a member of the founding team at SureWaves MediaTech, he was responsible for building pathbreaking products for the global broadcast industry. 

At Reshamandi, Manajit works at the cusp of business and technology, to create solutions and services that drive scale and business growth. His key role at Reshamandi is to transform Reshamandi to a product led organisation. In order to achieve this, he and his team’s focus is on building the next big thing at Reshamandi; Reshamandi Ecosystem, an integrated platform that provides end to end traceability across the natural fibre lifecycle from Produce to Product, enabling all stakeholders to derive value most relevant for them.

Manajit is a sports, travel and adventure enthusiast. His wide variety of life experiences and exposure to varied people and cultures allow him to offer unique insights into his customer personas.`,
    profile: "https://www.linkedin.com/in/manajitrath/",
  }
//   {
//     id: 4,
//     name: "Vikas Agarwal",
//     positionLabel: "Finance",
//     role: "Managing Partner, GoodHope Financials",
//     imageUrl: MP,
//     mailId: "vikas@baelworks.com",
//     about:
//       `Chartered Accountant with vintage of 30+ years experience in Corporate Finance, Capital Markets, Investment Banking, M&A, Strategy, Project Management, Business Advisory & Wealth Management across diverse industries.
      
// Vikas Agrawal advises Baelworks on fintech and financial solutions, BFSI, capital and venture financing and Innovation.`,
//     profile: "https://www.linkedin.com/in/vikas-agarwal-91250945",
//   },
//   {
//     id: 5,
//     name: "Manish R Chokse",
//     positionLabel: "Market Development",
//     role: "Market & Business Development, COO, Good Hope",
//     imageUrl: MDD,
//     mailId: "manish@baelworks.com",
//     about:
//       `Technocrat and accomplished Business Development and Ops professional with 28+ years experience across ITeS, Retail & eTail, Manish advises Baelworks for market and business development of Baelworks solutions across India, South East Asia, Middle east, Europe and APAC markets.`,
//     profile: "https://www.linkedin.com/in/chokse-r-manish-4a164648/",
//   }
]
