import React from "react";
import "./contactForm.css";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "./../FormikError/TextError";
import { usePostContactUsFormMutation } from "../../Services/dataService";

const ContactForm = () => {
  const [postContactUsForm, { isLoading, isSuccess }] =
    usePostContactUsFormMutation();
  const initialValues = {
    name: "",
    email: "",
    mobileNumber: "",
    located: "",
    onYourMind: "",
    checkBox: false,
  };
  const handleSubmit = async (values, props) => {
    let val = JSON.parse(JSON.stringify(values));
    delete val.checkBox;
    try {
      await postContactUsForm({ data: val }).unwrap();
      props.resetForm();
    } catch (error) {
    }
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Required*"),
    email: Yup.string().email("Invalid Mail").required("Required*"),
    located: Yup.string().required("Required*"),
    mobileNumber: Yup.number("This field can only contain numbers.")
      .required("Required*")
      .min(11111111, "Should not be less than 8")
      .max(9999999999999999, "Should not be more than 16")
      .typeError("This field can only contain numbers"),
    onYourMind: Yup.string().required("Required*"),
    checkBox: Yup.boolean().oneOf([true], "Required*"),
  });

  return (
    <>
      <section className="container my-lg-5 my-3">
        <div className="d-flex flex-column bd-highlight mb-3 py-5 text-black">
          <div className="bd-highlight text-center">
            <h6 className="ff-montserrat fs-4 mb-0">Got a Project ?</h6>
          </div>
          <div className="bd-highlight text-center">
            <h6 className="ff-playfair fs-2 fw-normal">Let's Talk</h6>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          className="contact-form"
        >
          <Form autoComplete="off">
            <div className="form-floating mb-3">
              <Field
                className="form-control border-0 border-bottom rounded-0 text-black"
                id="floatingName"
                placeholder="Name"
                name="name"
              />
              <ErrorMessage component={TextError} name="name" />
              <label
                htmlFor="floatingName required"
                className="fs-5 ff-montserrat"
              >
                Name <sup className="fw-semibold">*</sup>
              </label>
            </div>
            <div className="form-floating mb-3">
              <Field
                type="text"
                className="form-control border-0 border-bottom rounded-0"
                id="floatingEmail"
                name="email"
                placeholder="Email"
              />
              <ErrorMessage component={TextError} name="email" />
              <label
                htmlFor="floatingEmail required"
                className="fs-5 ff-montserrat"
              >
                Email Address <sup className="fw-semibold">*</sup>
              </label>
            </div>
            <div className="form-floating mb-3">
              <Field
                type="tel"
                className="form-control border-0 border-bottom rounded-0"
                id="floatingMobile"
                placeholder="Phone number"
                name="mobileNumber"
              />
              <ErrorMessage component={TextError} name="mobileNumber" />
              <label
                htmlFor="floatingMobile required"
                className="fs-5 ff-montserrat"
              >
                Phone Number <sup className="fw-semibold">*</sup>
              </label>
            </div>
            <div className="form-floating mb-3">
              <Field
                type="tel"
                className="form-control border-0 border-bottom rounded-0"
                id="floatingMobile"
                placeholder="Location"
                name="located"
              />
              <ErrorMessage component={TextError} name="located" />
              <label
                htmlFor="floatingMobile required"
                className="fs-5 ff-montserrat"
              >
                Where are you located<sup className="fw-semibold">*</sup>
              </label>
            </div>
            <div className="form-floating mb-3">
              <Field
                type="tel"
                className="form-control border-0 border-bottom rounded-0"
                id="floatingMobile"
                placeholder="Enter"
                name="onYourMind"
              />
              <ErrorMessage component={TextError} name="onYourMind" />
              <label
                htmlFor="floatingMobile required"
                className="fs-5 ff-montserrat"
              >
                What's on your mind<sup className="fw-semibold">*</sup>
              </label>
            </div>
            <div className="form-floating mb-3">
              <div className="text-justify">
                <Field type="checkbox" className="" name="checkBox" />
                <span className=" ff-montserrat px-2">
                  I am okay sharing my personal information with Baelworks and I
                  have had a look at your{" "}
                  <span className="fc-blue fw-bold">“Privacy Policy”</span> to
                  see how you use it.
                </span>
              </div>
              <ErrorMessage component={TextError} name="checkBox" />
            </div>
            <div className="button text-center py-3">
              {!isLoading && (
                <button
                  type="submit"
                  className="btn btn-lg btn-secondary rounded-0 fw-normal ff-montserrat px-5 py-2"
                >
                  Submit
                </button>
              )}
              {isLoading && (
                <button
                  className="btn btn-lg btn-secondary rounded-0 fw-normal ff-montserrat px-5 py-2"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Submit
                </button>
              )}
            </div>
          </Form>
        </Formik>
      </section>
      {isSuccess && (
        <section className="container my-lg-5 my-3">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="fs-2 text-color-primary ff-montserrat mb-4">
                Thanks, We got your message.
              </h2>
              <h5 className="ff-montserrat text-black fw-normal">
                We will get back in touch with you as soon as possible.
              </h5>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ContactForm;
