import slide1 from "./../assets/images/Slide_first_bg.png";
import slide2 from "./../assets/images/Slide_second_bg.png";
import slide3 from "./../assets/images/Slide_third_bg.png";

export const CarouselItems = [
    {
        id:0,
        title:"Innovate",
        description:"Work with us to help you innovate and create value across various dimensions such as network, structure, process, customer experience, products, services, brands and channels.. to know more.. please do reach out to us..",
        imageUrl:slide1,
        class:"carousel-item active"
    },
    {
        id:1,
        title:"Design",
        description:"From designing products and services to designing interactions, creating superlative user experiences and graphics and more, we work together with our clients and help them create powerful engagements for their customers",
        imageUrl:slide2,
        class:"carousel-item"
    },
    {
        id:2,
        title:"Build",
        description:"We have a strong team of creators, developers, technologists, architects across a wide range of frameworks and technology stacks to help craft solutions, products, services that create a wow. Talk to Us to learn more about our offerings and what we can do for you..",
        imageUrl:slide3,
        class:"carousel-item"
    },
]