import React from "react";
import { StoryItems } from "../../Data/Stories";
import "./stories.css";


const Stories = () => {
  return (
    <section className="my-lg-5 container-fluid px-lg-5 px-md-4 pb-10">
      <div className="section-heading py-3">
        <h2 className="ff-playfair fw-bold">Stories</h2>
      </div>
      <div className="row g-xxl-5 g-lg-3 g-md-3 pb-lg-5">
        {StoryItems.map((item) => (
          <div className="col-lg-4 col-md-4 col-sm-12" key={item?.id}>
            <div className="card border-0 h-100 pe-none rounded-0">
              <div className="content">
                <div className="img-hover pe-auto">
                  <img
                    src={item.imageUrl}
                    className="card-img-top rounded-0"
                    alt="story1"
                  />
                  <img
                    src={item.hoverUrl}
                    className="back from-bottom  card-img-top rounded-0"
                    alt="cdplogo"
                  />
                </div>
                <div className="card-body px-0 text-justify pe-none">
                  <div className="card-title fs-4 fw-semibold ff-montserrat text-black">
                    {item.title}
                  </div>
                  <p className="card-text fs-5 fw-normal ff-montserrat text-black">{item.content}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Stories;
