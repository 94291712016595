import other1 from "../assets/images/otherimage1.jpg"
import other4 from "../assets/images/Indus_TANTRA_Manufacturing.jpg"
import other6 from "../assets/images/other6.jpg"
import bg1 from "../assets/images/beautiful_agricultural_half_green.jpg";
import bg2 from "../assets/images/female_hacker_with_her_team_cyber_terrorists_making_dangerous_virus_attack_government.webp"
import bg3 from "../assets/images/Microsoft_Teams_image.png";
import bg4 from "../assets/images/three_male_engineers_planning_produce_auto_parts.png"
import bg5 from "../assets/images/industries_slide_6_71bb68d068.png"
import bg6 from "../assets/images/Social_baelworks_7f94ff15aa.jpg"
import bg7 from "../assets/images/programmer_typing_working_laptop_programming_about_cyber_security.jpg"

export const industries_card = [
    {
        id: 0,
        heading: "Agriculture",
        description: "Our understanding of the agri value chain beginning at the farm-level helps us connect deeply with challenges related to the farmers' decision making in the sale of their produce. This is based on the ground execution of several projects covering Agri-fintech solutions, farm data collection, mapping and management, output processing, POS systems for our customers, agri-equipments and agri-projects consulting. Our solutions Bharosa Data platform enables banks and financial institutions to collect, verify and analyse farm data across several parameters and simplify the process of taking financial solutions to farmers.",
        imgHeading: "Agriculture",
        imgContent: "Helping agriculture and food companies navigate disruptive trends and technologies.",
        bgImage: bg1,
        textWhite: true,
        head: "How We Help Clients",
        content: `We combine the power of our expertise in agriculture and technology and create solutions, products, services, systems for each step of the agri process map that we created after intensively studying the agriculture ecosystems and its players. 
        
We deliver transformational impact for small and medium food and agri companies looking for expanding , organizations by helping them adopt technology solutions, driving efficiencies, enhancing operational and organizational effectiveness. 
        
Our properietary solutions for agri and farm data, analytics capabilities, and our extensive experience are the mainstay of the value we bring to our clients.`,
        otherImage: other1
    },
    {
        id: 1,
        heading: "Information Security",
        description: "We offer products, services and consulting for our customers covering Information Security and Risk Management, ISO 31000:2018. We offer an immediate context and visibility of risks, enabling our clients to prioritize activities that strengthen compliance through risk identification and assessment, implementation, and consistent monitoring. We also provide a management view to provide a holistic picture of the overall certification and compliance system. It integrates multiple standards into a single management system, enabling clients to make quick, intelligent decisions to protect the enterprise, systems and data.",
        imgHeading: "Information Security",
        imgContent: "We work with our clients and enable them by simplifying ISO compliance, demystifying management mystems and help them manage risks better.",
        bgImage: bg2,
        textWhite: true,
        head: "How We Help our Clients",
        content: `How we help our clients

Management system certification provides a proven framework for the establishment, maintenance and continuous improvement of internal quality processes and other processes. It represents a global reference for market access and a ticket to trade. 

With proven experience in enabling technology solutions and services for organizations across the globe, we partner with clients to provide innovative and effective solutions based on their requirements. Our focus is to help transform business problems into practical solutions that help businesses become productive, agile, and adaptable with optimal technology use. In addition, we specialize in providing integration between the Customer’s existing systems.

We have worked with various industries including Pharmacy, Publishing, Managed Service Providers, Electronic component manufacturing and Security. We follow an agile approach:  Plan, Check, Act, Do, for each of our projects, working closely with our clients to give them the exact solutions they are looking for.

We also provide service consulting for each step of the software development life cycle, product development, and data analytics to drive business value. 

All projects are managed through a lifecycle model, where a product goes through a cycle of improvement and maintenance. 

To know more, talk to us.`
    },
    {
        id: 2,
        heading: "Education",
        description: "Baelwork Eduwits is a digital platform for educational institutes, schools, colleges, Universities and educational entities. Baelworks Eduwits brings together various stakeholders and enables the users for effective management of administrative, educational and other academic tasks. It also offers analytics capabilities to meaningfully understand the underlying data and draw valuable insights.",
        imgHeading: "Education",
        imgContent: "Baelworks Eduwits for schools, colleges, universities and other educational institutions ",
        bgImage: bg3,
        textWhite: false,
        head: "How We Help our Clients",
        content: `Baelworks Eduwits is a powerful suite of solutions for students and the stakeholders of a educational institutions including guardians, school management, teachers and administrative departments. 

Eduwits integrates your school management with robust ERP software that solves all your recurring problems and helps manage them efficiently. We are a trusted partner with a next-gen solution for your institutes! 

Youlars shield is the feature that focuses on the safety & security of the student’s commute. The guardians have to enable this feature in the app and the Suraksha Kavach is all set to go. Enable this feature and ensure your child’s safety. 

This feature focuses on providing motivation to the teachers by providing recognitions and awards to the best teacher. It also helps the school management in retaining their staff and making them give their best, throughout the year. 

Parents and teachers can easily interact with each other regarding their child’s progress. The relationship between students, parents, and teachers are boosted with efficient communication through Eduwits’ software. 

As a next-gen solution provider, Eduwits provides separate solutions for all on one dashboard. Parents can track their ward’s progress & fees due dates; & educators can track their student’s performance & manage their leave approvals.`    },
    {
        id: 3,
        heading: "Manufacturing",
        description: "Industry 4.0 is understood as the fourth industrial revolution (Hannover Messe, 2011); Baelworks offers IndusTANTRA a full-stack end-to-end solution for Small and Medium Business in Manufacturing. IndusTANTRA enables Monitor, Manage and Control capabilities for CNC/Robotic Machines, Manual Machines monitoring using indigenously built add-on hardware in Plug-n-play mode. Secure ownership and management of system and machine data. It provides RFID-enabled material tracking capabilities and has powerful Context Friendly Human Machine Interfaces for faster learning and smooth operations. It has Indigenous Scheduling and ERP integration and AI/ML powered Custom Analytics available on-demand.",
        imgHeading: "Manufacturing",
        imgContent: "A Made-in-Bharat, Made-for-the-World Industry 4.0 platform",
        bgImage: bg4,
        textWhite: true,
        head: "How We Help our Clients",
        content: `Industry 4.0 Investments have Grown ~10X Since 2011; Estimated to Double by 2025 to $200+ bn, Global Industry 4.0 Adoption with Emerging Economies Picking Up Pace, Indian Manufacturing has started Pivoting to Digitalization, with $5.5 - $6.5 bn spent on Indu0stry 4.0 in FY21. 

Given the rapid pace of Industry 4.0 adoption, we work closely with our clients using our IndusTANTRA platform in the manufacturing sector by bringing them indegenously built Industry 4.0 platform that enables them to 

-Monitor, Manage and Control capabilities for CNC/Robotic Machines 
- Manual Machines monitoring using indigenously built add-on hardware in Plug-n-play mode. 
- Secure ownership and management of system and machine data. 
- RFID enabled material tracking capabilities 
- Context Friendly Human Machine Interfaces for faster learning and smooth operations. 
- Indegenous Scheduling and ERP integration. 
- AI/ML powered Custom Analytics available on-demand.

The current IndusTANTRA Stack is a highly configurable, scalable, OT-IT unified platform`,
        otherImage: other4,
        text :"To know more, please reach out to Us"
    },
    {
        id: 4,
        heading: "Social",
        description: "Technology boosts innovation and is a multiplier for NGOs and Social Sector organizations working to create an impact on the ground, and very often, at the last mile. Technology enables NGOs change the way in which they communicate with the outside world — creating a more efficient and transparent transfer of information. This improves credibility among donors and society at large. Technology also helps improve the operational efficiency of projects, significantly reducing resource input and increasing the efficiency of communication. We at Baelworks help NGOs and Social sector organizations navigate challenges of limited budgets, tactical use of IT, strategic use of technology and competency.",
        imgHeading: "Social",
        imgContent: "Building solutions for good, helping scale delivery of services",
        bgImage: bg6,
        textWhite: false,
        head: "We Help our Clients",
        content: `Journey towards solving social challenges and critical issues is replete with challenges. 
Social sector organizations and NGO's constantly seek attention of the society, donation and resources from donors.

Even with donations in place, delivery of services and solutions to the respective sections of society require resources, efficient tracking of materials, demand visibility and ensuring correct reporting.

All these are needed to exert meaningul public influence and consistent interest of donors, government and society at large. Technology has been proposed and considered an effective solution to help NGOs address those challenges. 

Technology solutions while enable social sector organizations in scaling, instrumental in enabling efficiency they are unable to benefit from technology completely due to 

- Limited Budget 
- Tactical Use of Information Technology
- Strategic Technical Leadership and Competency. 

The needs of an NGO and Social sector organizations are no different from that of commercial organizations. The objective of creating meaningful impact on human lives despite the crunch for monetary and human resources, makes their needs more complex. 

Here are a few tools and ideas to help build technology that works for NGOs and organizations with similar challenges.`,
        otherImage: other6
    },
    {
        id: 5,
        heading: "CyberSecurity",
        description: "CyberStash is a solution that is engrained into eclipse.xdr and is a defensive threat-intelligence capability that significantly reduces the exposure to cyber-threats. It brings a threat detection methodology that minimizes the breach dwell-time through periodic forensic-depth compromise assessments. Using automated protection, detection and incident response, eclipse.xdr, it safeguards your most critical assets and allows you to establish and maintain trust in your IT environment – stopping attacks in their tracks.",
        imgHeading: "CyberSecurity",
        imgContent: "CyberSecurity solutions that push the boundaries of defending your network from cyber threats",
        bgImage: bg7,
        textWhite: true,
        head: "We Help Clients",
        content: `Priorities of a Cyber Defense platform,

1. A strong cybersecurity platform reduces the breach occurrence by significantly reducing the business exposure to sources of threats and optimize your incident response capability,

2. Reduces the complexity and saves time and valuable resources by optimizing threat intelligence collection, intelligence enrichment, enforcement, analysis, and reporting for decision making.

3. Proactively hunts to detects breached systems across your entire IT environment and eliminates the threat before irreversible business impact,

4. Establishes and maintains the highest level of trust in your IT environment through positive forensic-depth validation of system changes. 

5. Validates post-breach clean-up to ensure all backdoors, malware and human adversaries are successfully removed from your IT environment.`
    },
    {
        id: 6,
        heading: "Ecommerce",
        description: "Baelworks offers insights, solutions, services for ecommerce customers on various platforms such as Shopify, Magento, Microsoft Business Central and others. We have worked with clients and helped them transition from being an app and extensions player to being a SAAS platform for small retailers. We also offer buyer-seller apps for ONDC.",
        imgHeading: "Ecommerce",
        imgContent: "Ecommerce solutions for businesses",
        bgImage: bg5,
        textWhite: false,
        head: "We Help Clients",
        content: `Baelworks offers insights, solutions, services for ecommerce customers on various platforms such as Shopify, Magento, Microsoft Business Central. We have worked with our clients and helped them solve variety of business challenges, these include, helping transition from being a ecommerce app and extensions player to being a SAAS platform for small retailers, we also have buyer-seller apps for ONDC for Agri retailers, We have worked with our clients in helping them build order management, customer experience, inventory management, shipping & delivery management, order tracking and finance management, integration between Microsoft Business Central and Magento.
        
We help our clients solve different kinds of challenges for ecommerce, to know more please reach out to Us.`
    },
]