import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "./Carousel.css";
import { Indutries } from '../../Data/Industry.js';


const IndustryCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    cssEase: "linear",
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 770);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const className = isMobile ? 'background-blur' : '';

  return (
    <div className="d-flex justify-content-center py-2">
      <div className="carousel-container mt-n20 pt-2">
        <div className="text-white">.</div>
        <Slider {...settings}>
          {Indutries.map((ele, index) => {
            return (
              <div
                key={index}
                className="slick-slider d-flex justify-content-start"
              >
                <img
                  src={ele.imgUrl}
                  alt=""
                  className="w-100 card-img"
                />
                <div className="row card-img-overlay g-lg-3 g-md-3 h-100 px-lg-2 pt-lg-3 pt-md-2">
                  <div className="text-start col-xxl-5 col-lg-9 p-4 px-xl-5 d-flex align-items-center">
                    {/* <div className={className}> */}
                      <div className={`p-4 d-md-block ${ isMobile ? 'background-blur' : ele.imageDark ? "text-white" : "text-black"}`}>
                        <h1 className="mb-lg-3 ff-playfair">{ele.title}</h1>
                        <p className="lh-base fs-5 ff-montserrat fw-normal text-justify w-75">
                          {ele.about}
                        </p>

                      </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default IndustryCarousel;
