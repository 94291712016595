import React from "react";
import backArrow from "./../assets/icons/BackArrow.svg";
import linkedIn from "./../assets/icons/LinkedInAbout.png";
import Msg from "./../assets/icons/Msg.png";
import { useNavigate } from "react-router-dom";

const ExpertsDetails = () => {
  const navigate = useNavigate();
  const authorData = JSON.parse(localStorage.getItem("autherDetails"))
  
  return (
    <section className="pb-10">
      <div className="container-fluid p-4">
        <div className="container-fluid px-5">
          <div className="mb-3 px-5">
            <div className="d-inline-flex bd-highlight px-5">
              <div
                onClick={() => navigate(-1)}
                className="button--secondary backbtn py-lg-3"
              >
                <img src={backArrow} alt="back" />
              </div>
            </div>
            <div className="d-flex py-3">
              <div className="flex-shrink-0 pe-3 ps-5">
                <img src={authorData?.imageUrl} alt="Profile" />
              </div>
              <div className="flex-grow-1 ms-3">
                <div className="d-flex flex-column bd-highlight">
                  <div className="bd-highlight">
                    <h5 className="card-title fs-2 ff-playfair fw-bold">
                      {authorData?.name}
                    </h5>
                  </div>
                  <div className="my-2 bd-highlight">
                    <h6 className="ff-playfair h5 fw-medium">{authorData?.role}</h6>
                  </div>
                  <div className="bd-highlight">
                    <div className="d-flex flex-row bd-highlight">
                      <div className="bd-highlight">
                        <a
                          href={`https://${authorData.profile}`} rel="noreferrer" target="_blank"
                        >
                          <img src={linkedIn} alt="social links" />
                        </a>
                      </div>
                      <div className="ms-3 bd-highlight">
                        <a href={`mailto:${authorData?.mailId}`} >
                          {" "}
                          <img src={Msg} alt="social links" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-5">
          <div className="bd-highlight px-5">
            <pre style={{ whiteSpace: "pre-wrap" }} className="ff-montserrat fs-5 lh-lg text-justify px-5">
              {authorData?.about}
            </pre>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExpertsDetails;
